<template>
  <div class="integration-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!isLoadingIntegration && integration">
              <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="createForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(handleCreateIntegration)">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="integration-item">
                        <img :src="integration.icon" :alt="integration.title" class="integration-icon">
                        <div>
                          <h3>{{ integration.title }}</h3>
                          <p>{{ integration.description }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 mt-4">
                      <TextInput v-model="data.name" name="IntegrationName" label="Integration Name" sublabel="(*)"
                        rules="required" />
                    </div>
                  </div>
                  <Samcart v-if="integration.key === 'samcart'" :obj="data" />
                  <Sendgrid v-if="integration.key === 'sendgrid'" :obj="data" />
                  <Gogo v-if="integration.key === 'goto'" :obj="data" />
                  <Stripe v-if="integration.key === 'stripe'" :obj="data" />
                  <Facebook v-if="integration.key === 'fb'" :obj="data" />

                  <div class="d-flex mt-4">
                    <router-link :to="{ name: 'business.settings.integrations' }" class="btn btn-warning mr-4"><i
                        class="uil uil-arrow-left"></i> Back
                    </router-link>
                    <b-button variant="primary" type="submit" :disabled="processing || invalid">
                      <b-spinner v-if="processing" small />
                      <span v-else>{{ createButtonLabel }}</span>
                    </b-button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Samcart from './Components/Samcart.vue'
import Sendgrid from './Components/Sendgrid.vue'
import Gogo from './Components/Goto.vue'
import Stripe from './Components/Stripe.vue'
import Facebook from './Components/Facebook.vue'
import { makeString } from '@src/helper'
import uniqid from 'uniqid'

export default {
  components: {
    Samcart, Sendgrid, Gogo, Stripe, Facebook,
  },

  data() {
    return {
      isLoadingIntegration: false,
      integration: null,
      processing: false,
      data: {
        name: '',
        uid: undefined,
        url: '',
        redirect_url: '',
        api_key: '',
        syncData: false,
        code: undefined,
        options: {
        },
      },
      authWin: undefined,
    }
  },

  computed: {
    createButtonLabel() {
      let res = 'Create';
      if (this.integration && this.integration.auth_type === 'oauth')
      {
        if (this.integration.key === 'goto')
          res = 'Connect GoTo Account'
        else if (this.integration.key === 'stripe')
          res = 'Connect Stripe Account'
        else if (this.integration.key === 'fb')
          res = 'Connect Facebook Account'
      }
      return res
    }
  },

  mounted() {
    if (window.addEventListener) {
      window.addEventListener("message", this.postMessageHandler)
    } else {
      window.attachEvent("onmessage", this.postMessageHandler);
    }

    this.getIntegrationType()
  },

  beforeDestroy() {
    if (window.addEventListener) {
      window.removeEventListener('message', this.postMessageHandler);
    } else {
      window.removeEvent('onmessage', this.postMessageHandler);
    }
  },

  methods: {
    postMessageHandler(event)
    {
      let eventData = event.data
      if (eventData.a) // check if valid request
      {
        if (this.authWin)
        {
          this.authWin.close();
        }
        let err = eventData.c
        let code = this.decrtyp(eventData.b)
        if (code)
        {
          this.data.code = code
          this.createIntegration()
        } else {
          let errMsg = 'Something went wrong, Please try again later'
          if (err)
          {
            errMsg = eventData.d
          }
          
          Vue.$toast.open({
            message: errMsg,
            type: 'error',
            duration: 5000,
          })

          this.processing = false
        }
      }
    },

    getIntegrationType() {
      this.isLoadingIntegration = true
      const typeId = this.$route.query.id

      this.$store
        .dispatch('integration/type', typeId)
        .then((integration) => {
          this.integration = integration
          this.data.url = integration.url
          this.data.uid = integration.uid
          this.data.redirect_url = integration.redirect_url
          this.isLoadingIntegration = false
        })
        .catch(() => {
          this.isLoadingIntegration = false
          this.$router.push({ name: 'business.settings.integrations' })
        })
    },

    handleCreateIntegration() {
      if (this.integration.auth_type === 'oauth') {
        // this.processing = true

        let oauthUrl = this.integration.oauth_url
        let redirectUrl = this.data.redirect_url
        oauthUrl = oauthUrl + `?client_id=${this.integration.client_id}&response_type=code&redirect_uri=${encodeURIComponent(redirectUrl)}`

        let fullUrl = oauthUrl
        let winTitle = 'OAuth Connect'
        if (this.integration.key === 'goto')
        {
          fullUrl = 'https://authentication.logmeininc.com/login?service=' + encodeURIComponent(fullUrl)
          winTitle = 'GoTo OAuth Connect'
        } else if (this.integration.key === 'stripe') {
          winTitle = 'Stripe - Connect'
          let state = `${makeString(30)}${uniqid()}`
          let scope = 'read_write'
          this.data.options['state'] = state
          this.data.options['scope'] = scope

          fullUrl = fullUrl + `&scope=${scope}&state=${state}`
        } else if (this.integration.key === 'fb') {
          winTitle = 'Facebook - Connect'
          let state = `${makeString(30)}${uniqid()}`
          let scope = 'pages_show_list,leads_retrieval,pages_manage_ads,pages_manage_metadata,pages_read_engagement,business_management'
          this.data.options['state'] = state
          this.data.options['scope'] = scope

          fullUrl = fullUrl + `&scope=${scope}&state=${state}`
        }

        let w = 800
        let h = 800
        let left = (screen.width / 2) - (w / 2)
        let top = (screen.height / 2) - (h / 2)

        this.authWin = window.open(fullUrl, winTitle, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
      } else {
        this.createIntegration()
      }
    },

    createIntegration() {
      this.processing = true

      const param = {
        ...this.data,
        integrationId: this.integration.id,
      }

      this.$store
        .dispatch('integration/create', param)
        .then((res) => {
          this.processing = false
          this.$store.dispatch('auth/getProfile')
          this.$router.push({ name: 'business.settings.integrations', query: { tab: 1 } })
        })
        .catch(() => {
          this.processing = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.box-link {
  display: flex;
  justify-content: center;
}

.integration-item {
  border-radius: 5px;
  display: flex;
  align-items: center;

  h3 {
    flex: 1 1 auto;
    font-size: 18px;
  }

  .integration-icon {
    margin-right: 10px;
    max-width: 100px;
  }
}
</style>

<style lang="scss">
.integration-page {
  .copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      width: 16px;
      color: #5369f8;
    }
  }
}
</style>